// src/components/works.js
import React from "react"
import WORKS_IMG_1 from "../../asset/img/works/works_ecnow.png"
import WORKS_IMG_2 from "../../asset/img/works/works_hotlob.png"
import WORKS_IMG_3 from "../../asset/img/works/works_ima.png"
import WORKS_IMG_4 from "../../asset/img/works/works_makeshop.png"
import WORKS_IMG_5 from "../../asset/img/works/works_suntory.jpg"
import WORKS_IMG_6 from "../../asset/img/works/works_t&c.png"
import WORKS_IMG_7 from "../../asset/img/works/works_tunnel_tokyo.png"
import WORKS_IMG_8 from "../../asset/img/works/works_shashokulove.png"
import "../styles/works.css"
import Slick from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { FaWindowClose } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import Modal from "react-modal";
import { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import 'swiper/css';


export default function Works() {

  // モーダルウィンドウ設定
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedItem2, setSelectedItem2] = useState('')
  const WorksList = [
    { pic: WORKS_IMG_1, name: "EC NOW", type: "CONTENT PRODUCTION", url: "https://ecnow.jp/" },
    { pic: WORKS_IMG_2, name: "HOT LOBSTER", type: "E-COMERCE", url: "https://www.hotlobster.jp/" },
    { pic: WORKS_IMG_3, name: "株式会社i’ma", type: "WEB CREATION", url: "https://i-ma.tokyo/" },
    { pic: WORKS_IMG_4, name: "GMO MakeShop", type: "WEB CREATION", url: "https://www.fulloutsourcing.jp/logistics/" },
  ]
  const WorksList_2 = [
    { pic: WORKS_IMG_5, name: "サントリー", type: "CONTENT PRODUCTION", url: "" },
    { pic: WORKS_IMG_6, name: "株式会社T&C", type: "WEB CREATION", url: "https://tandc.tokyo/" },
    { pic: WORKS_IMG_7, name: "セガサミーホールディングス Tunnel Tokyo", type: "WEB CREATION", url: "https://tunnel-tokyo.jp/tunnel_tokyo_lp.html" },
    { pic: WORKS_IMG_8, name: "株式会社RETRY", type: "MARKETING CONSULTING", url: "https://shashoku-love.jp/lp/" },
  ]

  const onOpenDialog = (index) => {
    setSelectedItem(index)
  }
  const onOpenDialog2 = (index2) => {
    setSelectedItem2(index2)
  }

  const customStyles = {
    overlay: { zIndex: 1000 }
  };

  // モーダルスクロール防止
  

  return (
    <div className="works" id="works">
      <div className="works__heading">
        <Fade bottom delay={400}>
          <h2 className="common-heading" id="ワークス">
            WORKS
          </h2>
        </Fade>
      </div>
      <Swiper
        noSwiping={true}
        noSwipingClass={'swiper-no-swiping'}
        slidesPerView={3.5}
        // ブレイクポイント
        breakpoints={{
          // when window width is >= 320px
          10: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          768: {
            slidesPerView: 3.5,
            spaceBetween: 30
          },
        }}
        spaceBetween={30}
        speed={15000}
        autoplay={{
          delay: 0,
          // disableOnInteraction: false,
        }}
        freeMode={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}


        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >

        {WorksList.map((item, index) => {
          return (
            <SwiperSlide>
              <a className="swiper-no-swiping" onClick={() => { onOpenDialog(index) }}>
                <img src={item.pic} alt={item.name}></img>
                <div className="works__slider-textbox">
                  <div className="centering-text">
                    <p className="works__slider-title">{item.name}</p>
                    <p className="bullet"><span>・・・</span></p>
                  </div>
                </div>
              </a>
              <Modal style={customStyles} isOpen={selectedItem === index}>
                <div className="modal-close"><button onClick={() => setSelectedItem(false)}><FaWindowClose /></button></div>
                <div className="modal-container">

                  <div className="modal-works">
                    <div className="modal-img">
                      <img src={item.pic} alt={item.name} />
                    </div>
                    <h3>{item.name}</h3>
                    <p className="modal-type">{item.type}</p>
                    <p><a href={item.url}>{item.url}</a></p>
                  </div>
                </div>
              </Modal>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Swiper
        noSwiping={true}
        noSwipingClass={'swiper-no-swiping'}
        slidesPerView={3.5}
        spaceBetween={30}
        reverseDirection={true}
        speed={15000}
        autoplay={{
          delay: 0,
          reverseDirection: true,
        }}
        freeMode={true}
        loop={true}
        pagination={{
          clickable: true,
        }}

        // ブレイクポイント
        breakpoints={{
          // when window width is >= 320px
          10: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          768: {
            slidesPerView: 3.5,
            spaceBetween: 30
          },
        }}
        navigation={true}

        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >

        {WorksList_2.map((item, index) => {
          return (
            <SwiperSlide>
              <a className="swiper-no-swiping" onClick={() => { onOpenDialog2(index) }}>
                <img src={item.pic} alt={item.name}></img>
                <div className="works__slider-textbox">
                  <div className="centering-text">
                    <p className="works__slider-title">{item.name}</p>
                    <p className="bullet"><span>・・・</span></p>
                  </div>
                </div>
              </a>
              <Modal style={customStyles} isOpen={selectedItem2 === index}>
                <div className="modal-close"><button onClick={() => setSelectedItem2(false)}><FaWindowClose /></button></div>
                <div className="modal-container">

                  <div className="modal-works">
                    <div className="modal-img">
                      <img src={item.pic} alt={item.name} /></div>
                    <h3>{item.name}</h3>
                    <p className="modal-type">{item.type}</p>
                    <p><a href={item.url}> {item.url}</a></p>
                  </div>
                </div>
              </Modal>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  );
}