// src/components/news.js
import React from "react"
import "../styles/news.css"
import PostLink from "../components/post-link"
import { useStaticQuery, graphql } from "gatsby"
import Fade from 'react-reveal/Fade';



export default function News() {

  // contentfulの情報を取得する
  const data = useStaticQuery(
    graphql`
    query allContentfulPostNews {
      allContentfulPostNews {
        edges {
          node {
            title
            slug
            updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
            eventDate(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          }
        }
      }
    }
    `
  )
  return (
    <div className="news" id="news">
      <div className="news__heading">
      <Fade bottom delay={400}>
        <h2 className="common-heading" id="ニュース">
        NEWS
        </h2>
      </Fade>
      </div>
      <Fade bottom delay={400}>
        <div className="news__post">
      {data.allContentfulPostNews.edges.map(edge => (
        <PostLink key={edge.node.slug} post={edge.node} />
      ))}
        </div>
      </Fade>
    </div>
  )
}
