// src/components/hero.js
import React from "react"
import "../styles/hero.css"
import Fade from 'react-reveal/Fade';
import Slick from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import WORKS_IMG_TEST1 from "../../asset/img/fv_bg_1.jpg"
import WORKS_IMG_TEST2 from "../../asset/img/works_global_athlete.jpg"
import WORKS_IMG_TEST3 from "../../asset/img/works_suntry_holdings.jpg"
import WORKS_IMG_TEST4 from "../../asset/img/works_tunnel_tokyo.jpg"

export default function Hero() {

  const fv__settings = {
    autoplay: true,
    dots: false,
    fade: true,
    infinite: true,
    arrows:false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  
  return (
    <div className="hero">
      <div className="scrolldown"><span></span></div>
      <div className="hero__copyright"><p>@2022 JPholic.inc</p></div>
      <div className="inner --wide">
        <div className="hero__content">
        <Fade bottom delay={400}>
          <h1 className="hero__text">MAKE A FUN!</h1>
        </Fade>
        <Fade bottom delay={800}>
          <h2 className="hero__sub">
          Digital Marketing × Creation × People
          </h2>
        </Fade>
        </div>
      </div>

      <ul className="hero__slider">
      <Slick {...fv__settings}>
        <li className="hero__background"><div class="img-overlay"></div><img className="--filter" src={WORKS_IMG_TEST1} alt="テストサイト写真1"></img></li>
        {/* <li className="hero__background"><div class="img-overlay"></div><img src={WORKS_IMG_TEST2} alt="テストサイト写真2"></img></li>
        <li className="hero__background"><div class="img-overlay"></div><img src={WORKS_IMG_TEST3} alt="テストサイト写真3"></img></li>
        <li className="hero__background"><div class="img-overlay"></div><img src={WORKS_IMG_TEST4} alt="テストサイト写真4"></img></li> */}
      </Slick>
      </ul>
    </div>
  )
}
