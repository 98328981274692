// src/components/mission.js
import React from "react"
import "../styles/mission.css"
import MISSION_IMG from "../../asset/img/mission_img.jpeg"
import Fade from "react-reveal/Fade"
import Marquee from "react-double-marquee"

export default function Mission() {
  return (
    // <div className="mission --bgfilter" id="mission">

    //   <div className="mission__wrapper inner">
    //     <div className="mission__heading">
    //       <Fade bottom delay={400}>
    //         <h2 className="common-heading --white" id="企業理念">
    //           OUR MISSION
    //         </h2>
    //       </Fade>
    //     </div>
    //     <Fade bottom delay={400}>
    //       <div className="mission__title">
    //         <h3 className="common-title">
    //         みつける。 つくる。 つなぐ。
    //         </h3>
    //       </div>
    //     </Fade>
    //     <Fade bottom delay={400}>
    //       <div className="mission__text">
    //         <p className="common-text">
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
    //           exercitationem reiciendis ad dolores dolore, tempore nesciunt.
    //           Similique, quidem doloribus. Ex fugiat asperiores aspernatur error
    //           nam consectetur, et porro consequuntur fugit!
    //         </p>
    //       </div>
    //     </Fade>
    //   </div>
    //   <div className="test">

    //   <div className="mission__back-text"
    //   style={{
    //     width: '100vw',
    //     whiteSpace: "nowrap",
    //   }}>
    //       <Marquee speed={0.05} 
    //       direction={"left"}
    //       delay={0}
    //       childMargin={1}>
    //         SEEK. CREATE. CONNECT. SEEK. CREATE. CONNECT. SEEK. CREATE. CONNECT.
    //       </Marquee>
    //   </div>

    //   </div>
    // </div>

    <section className="mission">

      <div className="mission__inner">
        <div className="mission__wrapper inner">
          <div className="mission__heading">
            <Fade bottom delay={400}>
              <h2 className="common-heading --white" id="企業理念">
                OUR MISSION
              </h2>
            </Fade>
          </div>
          <Fade bottom delay={400}>
            <div className="mission__title">
              <h3 className="common-title">
                みつける。 つくる。 つなぐ。
              </h3>
            </div>
          </Fade>
          <Fade bottom delay={400}>
            <div className="mission__text">
              <p className="common-text">
                ただ見つける、ただ作る、ただ繋ぐ。<br />
                それだけではおわらせない。<br />
              </p>
              <p>
              JPholicは、みつけて、つくって、つなぐことで、<br />
                お客様と共に新たな価値を生み出していきます。
              </p>
            </div>
          </Fade>
        </div>
        <div className="mission__back-text">
          <div className="mission__back-text-wrapper"
            style={{
              width: '100vw',
              whiteSpace: "nowrap",
            }}>
            <Marquee speed={0.05}
              direction={"left"}
              delay={0}
              childMargin={1}>
              SEEK. CREATE. CONNECT. SEEK. CREATE. CONNECT. SEEK. CREATE. CONNECT.
            </Marquee>
          </div>
        </div>
        <div className="mission__image"></div>
      </div>
    </section>
  )
}
