// src/components/business.js
import React from "react"
import "../styles/business.css"
import Fade from 'react-reveal/Fade';
import "@fontsource/montserrat/700.css"
import { useState } from "react";
import Modal from "react-modal";
import { FaWindowClose } from "react-icons/fa";

export default function Business() {
  const customStyles = {
    overlay: { zIndex: 1000 },
  }

  // modal設定
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  // var subtitle;
  function afterOpenModal() {
    // subtitle.style.color = '#3ab60b';
  }

  const [selectedItem, setSelectedItem] = useState('')
  const ButtonList = [
    { name: "MARKETING CONSULTING", translate:"マーケティング コンサルティング", path: " M 60 210 A 140 140 176 1 1 139 326", desc: "お客様のサービスを成長させるため、WEB分析から、戦略立案、具体的な施策（SEO、WEB広告、コンバージョンレート改善）の提案から実施まで、一貫して提供します。" },
    { name: "E-COMMERCE", translate:"Eコマース", path: " M 243 333 A 140 140 72 1 1 337 229",desc: "自社ネットショップの運用のほか、ネットショップの制作、ネットショップの運用代行、コンサルティングを行っています。ネットショップ業務の周辺領域である商品撮影サービス、パッケージデザイン、ショップカードやチラシの制作なども承ります。" },
    { name: "CONTENT PRODUCTION", translate:"コンテンツ制作", path: " M 340 200 A 140 140 0 1 1 270 79",desc: "記事のライティング、スチール撮影、動画撮影、キャッチコピーやタグラインの制作など、売上UPにつながるコンテンツ制作しています。" },
    { name: "WEB CREATION", translate:"ウェブ制作", path: " M 134 76 A 140 140 242 1 1 60 195",desc: "WEBサイト（コーポレートサイト、サービスサイト、オウンドメディア、ランディングページ等）の制作をしています。ただ作っておしまいではなく、マーケティング視点に立った「売れるサイト」をお客様と一緒に作り上げます。" },
    { name: "REGIONAL REVITALIZATION", translate:"地方創生", path: " M 340 200 A 140 140 0 1 1 270 79",desc: "2021年の佐賀県有田町との進出協定を皮切りに、地方の隠れた魅力を伝えるためのプロジェクトを企画・運営しています。" },
  ]


  const onOpenDialog = (index) => {
    setSelectedItem(index)
  }




  return (
    <div className="business" id="business">
      <div className="business__wrapper inner">
        <div className="business__heading">
          <Fade bottom delay={400}>
            <h2 className="common-heading" id="ビジネス">
              BUSINESS
            </h2>
          </Fade>
        </div>
        <Fade bottom delay={400}>
          <div className="business__title">
            <h3 className="common-title">
            JPholicは <br />
            デジタルマーケティングカンパニーです。
            </h3>
          </div>
        </Fade>
        <Fade bottom delay={400}>
          <div className="business__text">
            <p className="common-text">
            川上の「分析/戦略立案」から<br className="--sp-only" />川中の「クリエイティブ制作」、<br className="--pc-only" />
            川下の「広告運用」や<br className="--sp-only" />「SNS運用」や「SEO」まで、<br className="--pc-only" />
            JPholicは売れる仕組み作りに必用な<br className="--sp-only" />ソリューションをワンストップで提供します。
            </p>
          </div>
        </Fade>

        <div className="about-steps">
          {ButtonList.map((item, index) => {
            return (
              <div className="modal-close">
                <button onClick={() => { onOpenDialog(index) }}>
                  <div className="about-circle">
                    <svg viewBox='59 59 282 282'>
                      <path d={item.path} />
                    </svg>
                    <h3>
                      {/* MARKETING<br></br>CONSULTING */}
                      {item.name}
                    </h3>
                    <p className="bullet">
                      <span>・・・</span>
                    </p>
                  </div>
                </button>
                <Modal
              isOpen={selectedItem  === index}
              // onAfterOpen={afterOpenModal}
              // onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modal-close"><button onClick={() => setSelectedItem(false)}><FaWindowClose /></button></div>
              <div className="modal-container">
                    <div className="modal-business">
                      <div className="modal-img">
                        <img src="" alt=""/>
                      </div>
                      <h3 className="modal-business__title">{item.name}</h3>
                      <p className="modal-business__subtitle">{item.translate}</p>
                      <p>{item.desc}</p>
                    </div>
                  </div>
            </Modal>
              </div>

            )
          })}
        </div>

      </div>
    </div>
  )
}
