// src/components/post-link.js
import React from "react"
import { Link } from "gatsby"
import "../styles/post-link.css"

export default function PostLink({ post }) {
  const { title, eventDate} = post
  // 投稿のスラッグをリンクとして作成するための変数
  const pageLink = `/post/${post.slug}/`

  return (
    <div className="post">
      <div className="post__wrapper inner">
        <Link to={pageLink} className="post__anchor">
          <div className="post__text">
            <p className="post__date">{eventDate}</p>
            <h2>{title}</h2>
          </div>
        </Link>
      </div>
    </div>
  )
}
