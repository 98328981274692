// src/components/message.js
import React from "react"
import "../styles/message.css"
import MESSAGE_IMG from "../../asset/img/message_img_2.jpeg"
import Fade from "react-reveal/Fade"
import { Parallax, ParallaxLayer } from "react-parallax"
import Parallax2 from "react-rellax"

export default function Message() {



  return (
    <div className="message">
      <div className="message__wrapper">
        <div className="message__content">
          <Fade bottom delay={400}>
            <div className="message__title common-title">日本中に夢中を<br /> つくりだす</div>
          </Fade>
          <Fade bottom delay={400}>
            <div className="message__text">
              暗くなるまで駆け回った鬼ごっこ。
              <br />
              寝るのも忘れてはまったゲーム。 <br />
              続編が待ち遠しかった小説。
              <br />
            </div>
            <div className="message__text">
            誰しも一度は夢中になった経験があるはず。
              <br />
              夢中は楽しい！夢中には熱がある！ <br />
            </div>
          </Fade>
        </div>
        <div className="message__img-wrapper">
        {/* <Parallax
          className="message__img"
          style={{
            height: 600,
            width: 500,
            marginTop: -200,
          }}
          bgImage={MESSAGE_IMG}
          strength={100}
        ></Parallax> */}
        <img className="--filter" src={MESSAGE_IMG} alt="" />

        </div>

{/* <div className="message__img">
  <Parallax2 speed={-2}>
  <img src={MESSAGE_IMG} alt="" />
  </Parallax2>
</div> */}

      </div>
    </div>
  )
}
