import React from "react"
import Business from "../components/business"
import Company from "../components/company"
import ContactButton from "../components/contact-button"

import Hero from "../components/hero"
import Layout from "../components/layout"
import Map from "../components/map"
import Message from "../components/message"
import Mission from "../components/mission"
import News from "../components/news"
import Seo from "../components/seo"
import Works from "../components/works"



export default function Home() {
  if (typeof window !== `undefined`) {

  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++){
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href');
      let targetElement = document.getElementById(href.replace('#', ''));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const gap = 160;
      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }
}

  return (
    <Layout>
      <Seo title="JPholic株式会社" 
      description="私たちはJPholic株式会社です。" 
      />

        <Hero />
        <Message />
        <Works />
        <Mission />
        <Business />
        <News />
        <Company />
        <ContactButton />
      <Map />
    </Layout>
  )
}
