// src/components/company.js
import React from "react"
import "../styles/company.css"
import Fade from 'react-reveal/Fade';

export default function Company() {
  return (
    <div className="company" >
      <div className="inner --wide">
        <div className="company__heading">
        <Fade bottom delay={400}>
          <h2 className="common-heading" id="会社概要">
          COMPANY
          </h2>
        </Fade>
        </div>
        <table className="company__table">
          <tr>
            <th>商号</th>
            <td>JPholic株式会社</td>
          </tr>
          <tr>
            <th>代表</th>
            <td>飯村　康男</td>
          </tr>
          <tr>
            <th>事業内容</th>
            <td>
              WEB制作事業/WEBコンサルティング事業/ <br />
              WEBマーケティング事業/ネットショップ事業
            </td>
          </tr>
          <tr>
            <th>設立</th>
            <td>2016年</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              142-0033
              東京都品川区西品川1-1-1住友不動産大崎ガーデンタワー9F
            </td>
          </tr>
          <tr>
            <th>E-mail</th>
            <td>
              info@jpholic.jp
            </td>
          </tr>
        </table>
      </div>



    </div>
  )
}
