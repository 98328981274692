// src/components/map.js
import React from "react"
import "../styles/map.css"
import Parallax2 from "react-rellax"

export default function Map() {
  return (
    <Parallax2 speed={0}>

    <div className="map">

          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.5186542364686!2d139.72887395090999!3d35.61493838011276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ca431e43023%3A0x6793ed7fea6fc6f1!2zSlBob2xpY-agquW8j-S8muekvg!5e0!3m2!1sja!2sjp!4v1654657242341!5m2!1sja!2sjp"
              width="100%"
              height="500px"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            /> 

    </div>
    </Parallax2>
  )
}
