// src/components/contact-button.js
import React from "react"
import "../styles/contact-button.css"
import "@fontsource/montserrat/400.css"
import CONTACT_IMG from '../../asset/img/contact-mail.png'
import { Link } from "gatsby"


export default function ContactButton() {



  return (
    <div>

      <Link to="https://form.run/@jinnouchi-1665983038" className="contact-button-item">
        <p>
        CONTACT
        </p>
        <img src={CONTACT_IMG} alt="" />
      </Link>
    </div>
  )
}
